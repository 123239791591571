<template>
  <div v-if="similars" class="article__similar">
    <div class="article__similar__item-title title">Похожие статьи</div>
    <div class="article__similar-items">
      <locale-router-link
        tag="div"
        :to="`articles/${item.id}`"
        v-for="(item, index) in similars"
        :key="index"
        class="article__similar-item"
      >
        <img
          v-lazy="getImage(item)"
          alt="similar article"
          class="article__similar-img"
        />
        <div class="article__similar-item-text">
          {{ item.attributes.Header }}/
          <span>{{ item.attributes.Type }}</span>
        </div>
      </locale-router-link>
    </div>
  </div>
</template>

<script>
import { getArticles } from "@/api/news";
export default {
  name: "ArticlesSimilar",
  data() {
    return {
      similars: null,
    };
  },
  created() {
    getArticles().then((articles) => {
      this.similars = articles.data.slice(0, 3);
    });
  },
  methods: {
    getImage(item) {
      return this.$helpers.getAbsolutePath(
        item?.attributes?.image?.data?.attributes?.url
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
